import React from 'react'
import { Box, Flex } from 'reflexbox'

import Markdown from 'src/components/Markdown/Markdown'

import * as styles from './Footer.module.scss'

const Footer = (props: any) => {
  const { blok, header } = props
  const { title, preamble, address_field_1, address_field_2 }: any = blok
    ? blok.content
      ? blok.content
      : blok
    : {}
  const color = '#063136'
  const { links }: any = header ? header.content : {}
  return (
    <React.Fragment>
      <section bgcolor={color} textcolor="white">
        <div id={'footer'} />
        <Flex
          paddingX={[7, null, null, 16]}
          paddingTop={[18, null, null, '180px']}
          paddingBottom={[14]}
          className={styles.wrapper}
          flexDirection="column"
          as="footer"
          width={1}
        >
          <Flex style={{ zIndex: 1 }} width={1} flexDirection="column">
            <Box marginBottom={[14]} className={styles.title} as="h2">
              {title}
            </Box>
            <Box
              width={[1, null, null, 7 / 12]}
              className={styles.description}
              as="p"
            >
              {preamble}
            </Box>
            <Flex
              paddingLeft={[0, null, null, `${(4 / 12) * 100}%`]}
              width={1}
              marginTop={[34]}
              flexDirection={['column', null, null, 'row']}
              className={styles.address}
            >
              <Box width={[1, null, null, 3 / 8]}>
                <Markdown>{address_field_1}</Markdown>
              </Box>
              <Box width={[1, null, null, 3 / 8]}>
                <Markdown>{address_field_2}</Markdown>
              </Box>
            </Flex>
          </Flex>
          <Flex
            marginTop={['200px']}
            style={{ zIndex: 1 }}
            width={1}
            flexDirection="column"
            paddingLeft={[`${(1 / 4) * 100}%`, null, null, 0]}
          >
            <Flex
              marginBottom={['124px', null, null, '190px']}
              flexDirection="column"
            >
              {links &&
                links.length > 0 &&
                links.map((link: any) => (
                  <Box className={styles.link} key={link._uid}>
                    <Box
                      paddingX={0}
                      as="button"
                      onClick={() => {
                        if (link.go_to_hash) {
                          const el = document.getElementById(link.go_to_hash)
                          if (el) {
                            el.scrollIntoView({ behavior: 'smooth' })
                          }
                        }
                      }}
                    >
                      {link.title}
                    </Box>
                  </Box>
                ))}
            </Flex>
          </Flex>
          <svg className={styles.svg} viewBox="0 0 794 1411" fill="none">
            <path
              d="M651.189 381.65l302.754 788.95c2.207-107.18 43.82-213.324 123.837-312.9 87.61-109.067 188.67-171.104 192.93-173.672l17.01 28.358c-3.97 2.425-394.947 244.756-280.21 597.814l1.68 4.37c2.38.43 4.8.52 7.12 1.52 38.45 16.64 111.42 34.52 215.47 14.67 93.94-17.93 262.33-138.19 355.55-258.24 34.42-44.34 23.16-105.417-16.48-137.845-15.14-12.387-33.92-19.302-53.25-20.88-19.83-1.62-59.71-6.076-56.87-15.576 68.96-231.186 27.94-504.912 27.94-504.912-135.97-17.725-306.05 6.697-390.68 42.935-17.15 7.335-34.75-2.635-43.07-19.663C890.198 79.255 487.379.215 487.379.215l-2.769 1.066-2.769 1.066S235.358 330.7 338.577 691.657c5.21 18.228-1.191 37.413-18.852 43.439C232.597 764.805 89.853 860.503.67 964.686c0 0 152.619 230.874 358.513 356.514 8.457 5.17-18.198 35.17-31.848 49.64-13.315 14.11-22.636 31.81-25.606 51.16-7.769 50.63 24.725 103.56 79.963 113.46 149.582 26.82 355.157 3.48 436.966-46.06 90.601-54.9 132.861-117.04 150.303-155.13 1.057-2.3 2.794-3.99 4.279-5.9l-1.678-4.37c-150.899-339.172-603.553-257.56-608.126-256.71l-6.326-32.47c4.883-.94 121.484-22.48 259.555-.07 126.065 20.45 227.993 71.47 301.328 149.65L615.239 395.455l35.95-13.805z"
              fill="#174E3B"
            />
          </svg>
        </Flex>
      </section>
    </React.Fragment>
  )
}

export default Footer
