export const isExternalUrl = (url: string) => {
  const isExternal =
    url.substring(0, 4) === 'http' ||
    url.substring(0, 6) === 'mailto' ||
    url.substring(0, 3) === 'tel' ||
    url.substring(-4, 4) === '.pdf' ||
    url.substring(0, 9) === 'data:text'
      ? true
      : false

  return isExternal
}
