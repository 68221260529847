import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'

// import seo from 'src/json/seo.json'

import SbEditable from 'storyblok-react'
import { reaction } from 'mobx'

import Header from 'src/storyblok/GlobalModules/Header/Header'
import Footer from 'src/storyblok/GlobalModules/Footer/Footer'
import SEO from 'src/components/SEO/SEO'
import useStore from 'src/stores/useStore'
import { getSeo } from 'src/helpers/getSeo'

import { Props } from './types'

export default ({ children, pageContext }: Props) => {
  const [sections, setSections]: any[] = useState([])
  const { title, description, share_image }: any = {}
  const { ui } = useStore()
  const { globalData }: any = pageContext ? pageContext : {}
  const { header, footer }: any = globalData ? globalData : {}
  const { global_seo }: any = header ? header.content : {}
  const { seo_description, seo_title, seo_image } = getSeo(
    global_seo ? global_seo[0] : null
  )

  const onResize = () => {
    ui?.setWindowSize({
      height: window.innerHeight,
      width: window.innerWidth,
    })
  }

  const percentageSeen = (section: any) => {
    // Get the relevant measurements and positions
    const viewportHeight = window.innerHeight
    const scrollTop = window.scrollY
    const elementOffsetTop = section.el.offsetTop
    const elementHeight = section.el.offsetHeight

    // Calculate percentage of the element that's been seen
    const distance = scrollTop + viewportHeight - elementOffsetTop
    const percentage = Math.round(
      distance / ((viewportHeight + elementHeight) / 100)
    )

    // Restrict the range to between 0 and 100
    return {
      ...section,
      visible: Math.min(100, Math.max(0, percentage)),
    }
  }

  const onScroll = (array: any) => {
    const test = array.map((section: any) => {
      return percentageSeen(section)
    })
    let el = {
      color: '#EBC1A9',
      visible: 0,
    }
    let prevFull = false
    test.forEach((section: any, index: number) => {
      const treshold = index === test.length - 1 ? 30 : 20
      if (section.visible > el.visible) {
        el = section
      }
      if (prevFull && section.visible > treshold) {
        el = section
      }
      if (section.visible > 80) {
        prevFull = true
      } else {
        prevFull = false
      }
    })

    if (el && el.color) {
      document.body.style.background = el.color
      if (el.text === 'white') {
        document.body.classList.add('light')
      } else {
        document.body.classList.remove('light')
      }
    }
  }

  React.useEffect(() => {
    if (document) {
      const sections = document.getElementsByTagName('section')
      if (sections) {
        const array: any[] = []
        for (const item of sections) {
          array.push({
            color: item.getAttribute('bgcolor'),
            text: item.getAttribute('textcolor'),
            el: item,
          })
        }
        onScroll(array)
        window.addEventListener('scroll', () => onScroll(array), {
          passive: true,
        })
      }
    }
    const disposer = reaction(
      () => ui.activeMenu,
      () => {
        if (ui.activeMenu) {
          document.body.classList.add('prevent-overflow')
        } else {
          document.body.classList.remove('prevent-overflow')
        }
      }
    )
    ui?.setWindowSize({
      height: window.innerHeight,
      width: window.innerWidth,
    })
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
      window.removeEventListener('scroll', onScroll)
      disposer()
    }
  }, [])

  return (
    <React.Fragment>
      {header && (
        <SbEditable content={header.content}>
          <Header blok={header} />
        </SbEditable>
      )}
      {(seo_description || seo_title || seo_image) && (
        <React.Fragment>
          <Helmet
            titleTemplate={`${seo_title} | %s`}
            defaultTitle={seo_title}
          />
          <SEO metaDescription={seo_description} image={seo_image} />
        </React.Fragment>
      )}
      {children}
      {footer && (
        <SbEditable content={footer.content}>
          <Footer blok={footer} header={header} />
        </SbEditable>
      )}
    </React.Fragment>
  )
}
