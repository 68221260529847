import React, { ReactElement, FC } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkBreaks from 'remark-breaks'
import { Link } from 'gatsby'

import { isExternalUrl } from 'src/helpers/linkHelpers'

// import LinkComponent from '../LinkComponent/LinkComponent'

import * as styles from './Markdown.module.scss'

interface Props {
  children: ReactElement | null | string | undefined
  className?: string
  noParagraphs?: boolean
}

/* eslint-disable */
const LinkRenderer = ({
  href = '',
  children,
}: {
  href: string
  children: any
}) => {
  const isExternal = isExternalUrl(href)
  const title =
    children && children.length > 0 && children[0].length > 0 ? children[0] : ''

  return isExternal ? (
    <a href={href} title={title} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  ) : (
    <Link to={href}>{children}</Link>
  )
}

const Markdown: FC<Props> = ({
  children,
  className = '',
  noParagraphs = false,
}) => {
  const remarkPlugins = [remarkBreaks]

  const markdownProps: any = {
    noParagraphs,
    remarkPlugins,
    children,
  }

  if (noParagraphs) {
    markdownProps.disallowedTypes = ['paragraph']
    markdownProps.unwrapDisallowed = true
  }

  return (
    <div className={[styles.markdown, className ? className : ''].join(' ')}>
      {children && (
        <ReactMarkdown
          components={{
            a({ href = '', children }) {
              return LinkRenderer({
                href,
                children,
              })
            },
          }}
          {...markdownProps}
        >
          {children}
        </ReactMarkdown>
      )}
    </div>
  )
}

export default Markdown
