import React from 'react'
import { configure } from 'mobx'
import { Location } from '@reach/router'
import { ThemeProvider } from 'emotion-theming'
import Ui from 'src/stores/ui/ui'
import { StoreTypes } from 'src/stores/types'

import { Props } from './types'

const theme = {
  breakpoints: ['374px', '767px', '1024px', '1099px', '1920px'],
  space: [...Array(35)].map((_, i) => i * 4),
}

export const stores: StoreTypes = {
  ui: new Ui(),
}

configure({
  enforceActions: 'always', // enforces @action to update mobx states
})

export const StoresContext = React.createContext<StoreTypes>(stores)

export default ({ element }: Props) => {
  return (
    <StoresContext.Provider value={stores}>
      <ThemeProvider theme={theme}>
        <Location>
          {({ location }) => {
            return element
          }}
        </Location>
      </ThemeProvider>
    </StoresContext.Provider>
  )
}
